<template>
  <v-card class="mx-auto mt-3" max-width="400">
    <v-img
      class="error--text align-end"
      src="../../../public/senora_acuaria.jpg"
    >
    </v-img>
    <v-card-title>Señora Acuaria</v-card-title>
    <v-card-text class="pb-0">Entidad Cósmica del Rayo Violeta <br> Regente de la Era de Acuario </v-card-text>

    <v-divider class="mx-4"></v-divider>
    <v-card-subtitle class="pb-0"> DECRETO DE LA SEMANA </v-card-subtitle>

    <v-card-text class="text--primary" >
      <div class="pt-3">
        “Yo Soy” la Ley de la Libertad y la Virtud Cósmica del Progreso de la Señora Acuaria, que estimula e intensifica el crecimiento espiritual en mi y en toda la humanidad.
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "SerDeLuz",
};
</script>