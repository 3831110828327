<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <h3 class="mt-5 pt-4">Articulos anteriores</h3>
    </v-row>
    <v-row class="d-flex justify-center mt-1">
      <v-col cols="12" lg="4" class="mt-2" v-for="(card, i) in cards" :key="i">
        <v-card>
          <v-img :src="card.src" height="325px"></v-img>
          <h4 class="ma-4" v-text="card.titulo"></h4>
          <v-card-text class="pt-0" v-text="card.texto"></v-card-text>
          <v-card-actions>
            <v-btn text color="indigo accent-4" :to="card.to"> LEER</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'OtrosArticulos',

  data() {
    return {
      cards: [
      {
          titulo: 'Hilarión',
          src: `${process.env.BASE_URL}hilarion.jpg`,
          flex: '4',
          texto: 'Maestro Ascendido que dirige para la Tierra, el Quinto Rayo Verde de la Verdad, Salud, Vida...',
          to: `articulos/hilarion`,
        },
      {
          titulo: 'Espíritu de Paz Navideño',
          src: `${process.env.BASE_URL}espiritu_de_paz_navideno.png`,
          flex: '4',
          texto: 'La paz es un estado de consciencia interno de perfecto equilibrio, que...',
          to: `articulos/espiritu_de_paz_navideno`,
        },
      {
          titulo: 'El árbol de Navidad eres tú',
          src: `${process.env.BASE_URL}el_arbol_de_navidad_eres_tu.jpg`,
          flex: '4',
          texto: 'Simbólicamente, el árbol de Navidad somos nosotros con nuestro..',
          to: `articulos/el_arbol_de_navidad_eres_tu`,
        },
      ],
    };
  },
};
</script>
