<template>
  <v-card class="mx-auto mt-3" max-width="400">
    <v-img
      class="error--text align-end"
      src="../../../public/asclepio.jpg"
    >
    </v-img>
    <v-card-title>Asclepio</v-card-title>
    <v-card-text class="pb-0">Señor de la Medicina </v-card-text>

    <v-divider class="mx-4"></v-divider>
    <v-card-subtitle class="pb-0"> DECRETO DE LA SEMANA </v-card-subtitle>

    <v-card-text class="text--primary" >
      <div class="pt-3">
        “Yo Soy” la Ley de la Salud y la Llama Verde de la Curación Divina que cura y sana toda apariencia de enfermedad en mi y en toda la humanidad.
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "SerDeLuz",
};
</script>