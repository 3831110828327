<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-card color="grey lighten-5">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline">
                Bicipositiva</v-card-title
              >

              <v-card-subtitle
                >Andar en bicicleta es lo más parecido a volar. Es una actividad del Quinto Rayo Verde de la Vida y Curación...
                </v-card-subtitle
              >
              <v-card-actions>
                <v-btn text color="indigo accent-4" @click="irArticulo3">
                  Leer artículo
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar class="ma-3 ultimos-articulos__imagen" size="100" tile>
              <v-img src="../../../public/bicipositiva.jpg"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>


      <v-col cols="12">
        <v-card color="grey lighten-5">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline">
                Acuaria</v-card-title
              >

              <v-card-subtitle
                >La Era de Acuario, edad en la que nos encontramos, tiene como Entidad Cósmica Guardiana a la Señora Acuaria, Ser...
              </v-card-subtitle>
              <v-card-actions>
                <v-btn text color="indigo accent-4" @click="irArticulo2">
                  Leer artículo
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar class="ma-3 ultimos-articulos__imagen" size="100" tile>
              <v-img src="../../../public/acuaria.jpg"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card color="grey lighten-5">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="headline">
                Manifiesto de la Verdad
              </v-card-title>
              <v-card-subtitle
                >"Yo Soy” el Espíritu de la Verdad. En la Verdad yo vivo, me muevo y tengo mi ser. Yo formo parte de la expresión "...</v-card-subtitle
              >
              <v-card-actions>
                <v-btn text color="indigo accent-4" @click="irArticulo1">
                  Leer artículo
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar class="ma-3 ultimos-articulos__imagen" size="100" tile>
              <v-img src="../../../public/pallas_atenea.jpg"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card color="indigo lighten-5">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div class="ultimos-articulos__llave-tonal">
              <v-card-title class="headline">
                Llave tonal de la semana</v-card-title
              >

              <v-card-subtitle class="pb-1"
                >Llave Tonal del Amado Asclepio</v-card-subtitle
              >
              <v-card-text class="pb-0"
                >"Una furtiva lágrima" de la ópera "Elissir d'amor", de Gaetano Donizetti.</v-card-text
              >
              <v-card-actions class="ultimos-articulos__audio">
                <audio
                  src="../../assets/audio/16 UNA FURTIVA LAGRIMA OPERA ELISSIR D'AMORE - GAETANO DONIZETTI - SENOR ASCLEPIO.mp3"
                  autoplay
                  controls
                ></audio>
              </v-card-actions>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "UltimosArticulos",
  data() {
    return {
      url: process.env.BASE_URL,
    };
  },
  methods: {
    irArticulo1() {
      this.$router.push("articulos/manifiesto_de_la_verdad");
    },
    irArticulo2() {
      this.$router.push("articulos/acuaria");
    },
    irArticulo3() {
      this.$router.push("articulos/bicipositiva");
    },
  },
};
</script>
<style lang="scss">
@media (max-width: 700px) {
  .ultimos-articulos__imagen {
    display: none;
  }

  .ultimos-articulos__llave-tonal {
    max-width: 100%;
  }

  .ultimos-articulos__audio {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
  }
}
</style>
