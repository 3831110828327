<template>
  <v-container>
    <v-row class="d-flex align-start">
      <v-col lg="9" class="my-5 pr-5">
        <h1 class="indigo--text text--lighten-1 text-center">
          UN AÑO CON EMMET FOX
        </h1>
        <div class="d-flex justify-center mb-5">
          <v-img src="../../public/emmet_fox.png" max-width="40%"></v-img>
        </div>
        <p class="mb-5 text-center">{{ efHoy.dia }} | {{ efHoy.titulo }}</p>

        <p class="text-justify" v-html="efHoy.cuerpo"></p>
      </v-col>
      <v-col lg="3" class="pl-2"><PanelDerecho /></v-col>
    </v-row>
  </v-container>
</template>
<script>

import PanelDerecho from "../components/Inicio/PanelDerecho";
export default {
  name: "UnAnoConEmmetFox",
  components: {
    PanelDerecho,
  },
  computed: {
    efHoy() {
      const emmetFoxHoy = this.$route.params.id;
      return this[emmetFoxHoy];
    },
  },
  data() {
    return {
      enero26: {
        dia: "26 DE ENERO",
        titulo: "LA LEY DE LA PRÁCTICA",
        cuerpo: "<p>La práctica hace la perfección. Este familiar proverbio personifica una de las grandes leyes de la naturaleza humana y siendo una ley, nunca es rota bajo ninguna circunstancia.</p><p>Es simple, no hay logro sin práctica y la mayor práctica, con tal que sea hecha inteligentemente, con la mayor voluntad, competencia y la mayor prontitud, será lograda. Esto es verdadero en toda rama del esfuerzo humano. La práctica es el precio de la competencia.</p><p>En metafísica, los efectos de esta ley son particularmente notables. El control del pensamiento, es enteramente una cuestión de práctica inteligente.  Y la verdadera religión, puede bien ser resumida como la Práctica de la Presencia de Dios.</p><p><i>…sed hacedores de la palabra, y no tan solamente oidores…</i> (Santiago 1:22)</p>"
      },
      enero27: {
        dia: "27 DE ENERO",
        titulo: "LA LEY DEL PERDÓN",
        cuerpo: "<p>Es una inquebrantable ley mental, que tu tienes que perdonar a otros, si quieres superar tus dificultades y hacer algún real progreso espiritual.</p><p>La vital importancia del perdón, puede no ser obvia a primera vista, pero puedes estar seguro que no es por casualidad que todo gran maestro espiritual desde Jesús Cristo hacia abajo han insistido tan fuertemente sobre eso. </p><p>Tu debes perdonar injurias, no solo en palabras o externamente sino también en tu corazón; y eso es lo extenso y lo breve de ello. Tú haces esto no por el bien de los demás sino por el tuyo. El resentimiento, la condenación, la ira, desear ver a alguien castigado, son cosas que pudren tu alma. Cosas como estas, atan tus problemas a ti con remaches. Te ponen grilletes a otros problemas, que en realidad no tienen en absoluto nada que ver con las quejas originales en sí.</p><p><i>No devolviendo mal por mal, ni maldición por maldición, sino por el contrario bendiciendo, sabiendo que fuisteis llamados para que heredaseis bendición.</i> (I Pedro 3:9)</p>"
      },
      enero28: {
        dia: "28 DE ENERO",
        titulo: "LA LEY DUAL DEL PENSAMIENTO",
        cuerpo: "<p>Todo pensamiento está hecho de dos factores, conocimiento y sentimiento. Un pensamiento consiste de una parte de conocimiento con una carga de sentimiento, y es el sentimiento en sí mismo lo que da poder al pensamiento. No importa cuan importante o magnificente pueda ser el contenido de conocimiento, si no hay sentimiento adherido a él, nada pasará. Por otra parte, no importa cuán sin importancia o insignificante pueda ser el contenido de conocimiento, si hay una gran carga de sentimiento algo pasará.</p><p>No hay diferencia si el contenido de conocimiento es correcto o no tanto, como si tú lo crees correcto. Recuerda que es lo que realmente creemos lo que importa, un rumor sobre algo puede ser totalmente falso, pero si tu lo crees, tiene el mismo efecto sobre ti como si fuera verdad, y ese efecto otra vez dependerá de la cantidad de sentimiento adherido a él.</p><p>Cuando entendemos esta ley, vemos la importancia de aceptar la verdad con alegría en toda fase de nuestra experiencia.</p><p><i>Me mostrarás la senda de la vida; en tu presencia hay plenitud de gozo… (Salmo 16:11)</i></p>"
      },
      enero29: {
        dia: "29 DE ENERO",
        titulo: "LA LEY DE CRECIMIENTO",
        cuerpo: "<p>Aquello sobre lo que piensas aumenta. Esta es una máxima oriental y resume la más grande y fundamental de todas las leyes de la mente.</p><p>Aquello sobre lo que piensas aumenta. Lo que sea que tu permitas que ocupe tu mente, lo aumentas en tu vida. Si el sujeto de tu pensamiento es bueno o malo, la ley trabaja y la condición aumenta. Cualquier tema que mantengas fuera de tu mente tiende a disminuir en tu vida, porque lo que no usas se atrofia.</p><p>Cuando más pienses en tus quejas o en las injusticias que has sufrido, más del mismo tipo de dificultades continuarás recibiendo. Cuanto más pienses en la buena fortuna que tienes, más buena fortuna vendrá a ti.</p><p>Esto es la básica, fundamental y toda incluyente ley de la mente. Y en realidad toda enseñanza psicológica y metafísica es poco más que un comentario sobre esto.</p><p>Aquello sobre lo que piensas aumenta. </p><p><i>Por lo demás, hermanos, todo lo que es verdadero, todo lo honesto, todo lo justo, todo lo puro, todo lo amable, todo lo que es de buen nombre; si hay virtud alguna, si algo digno de alabanza, en esto pensad.</i> (Filipenses 4:8) </p>"
      },
            enero30: {
        dia: "30 DE ENERO",
        titulo: "EL ESPLENDOR APRISIONADO",
        cuerpo: "<p>La verdad está dentro nuestro, no detiene la subida<br>Desde las cosas exteriores, las que puedes creer.<br>Hay un centro recóndito en todos nosotros,<br>Donde la verdad permanece en plenitud y alrededor,<br>Pared sobre pared, la grosera carne la encierra dentro,<br>Esta perfecta, clara percepción -la cual es la verdad-.</p><p>Una desconcertante y pervertidora malla carnal<br>La amarra y comete todo error: y saber,<br>Consiste bastante en ensanchar un camino<br>De donde el esplendor aprisionado pueda escapar,<br>Que en efectuar una entrada para una luz<br>Supuesta de estar afuera.</p><p>(Robert Browning, “Paracelso”. Parte I).</p><p><i>… y conoceréis la verdad, y la verdad os hará libres.</i> (Juan 8:32).</p>"
      },
      enero31: {
        dia: "31 DE ENERO",
        titulo: "EL PADRE NUESTRO",
        cuerpo: "<p>El Padre Nuestro es el más importante de todos los documentos cristianos. Cualquiera que está buscando seguir el camino que Jesús indicó, debe rezar el Padre Nuestro inteligentemente todos los días.</p><p>La Gran Plegaria, es una fórmula compacta para el desarrollo del alma. Está diseñada con el máximo cuidado para un propósito específico, aquel que la use regularmente, con entendimiento, experimentará un cambio real en el alma. Es el cambio del alma lo que importa. La mera adquisición de conocimiento recibido intelectualmente, no hace cambiar el alma.</p><p>La primera cosa que advertimos, es que la oración, naturalmente, consta de siete cláusulas. Esto es muy característico de la tradición oriental. El siete simboliza la totalidad individual, la perfección del alma individual, lo mismo que el número veinte, convencionalmente, simboliza la totalidad colectiva. Las siete cláusulas son juntadas en perfecto orden y secuencia, y ellas contienen todo lo necesario para el alimento del alma. Más se analiza el Padre Nuestro, más de su maravillosa construcción se puede ver.</p><p><i>Vosotros pues, oraréis así…</i> (Mateo 6:9).</p>"
      },
      febrero1: {
        dia: "1 DE FEBRERO",
        titulo: "PADRE NUESTRO",
        cuerpo: "<p>Esta simple afirmación fija claramente la naturaleza y carácter de Dios. Dice todo lo que el hombre necesita saber de Dios, acerca de sí mismo y acerca de su prójimo. Oliver Wendel Holmes dijo: 'Mi religión esta resumida en las primeras dos palabras del Padre Nuestro'.</p><p>‘Padre Nuestro’. En esta cláusula, Jesús establece de una vez y por todas que la relación entre Dios y el hombre es de padre e hijo. Esto elimina cualquier posibilidad de que la Deidad pueda ser el implacable y cruel tirano. La mayoría de los hombres y las mujeres son lo mejor que puedan en su trato con sus hijos. Hablando de la misma verdad en otra parte Jesús dijo:</p><p><i>Pues, si vosotros siendo malos, sabéis dar buenas dádivas a vuestros hijos, ¿cuánto más vuestro padre que está en los cielos dará buenas cosas a los que le pidan?</i> (Mateo 7:11).</p>"
      },
            febrero2: {
        dia: "2 DE FEBRERO",
        titulo: "PADRE NUESTRO",
        cuerpo: "<p>Nótese que esta cláusula que fija la naturaleza de Dios, al mismo tiempo fija la naturaleza del hombre. Es una ley cósmica que lo semejante engendra lo semejante. No es posible que un rosal produzca lirios, o que una vaca pueda dar nacimiento a un potrillo. La prole debe ser de la misma naturaleza que los padres y de esta manera, ya que Dios es Espíritu Divino, el hombre debe esencialmente ser Espíritu Divino también, sea lo que fuere que las apariencias puedan decir al contrario.</p><p>De un simple golpe esta enseñanza de Jesús, barre afuera el noventa y nueve por ciento de toda la vieja teología, con su Dios vengativo, sus individuos elegidos y favorecidos, su eterno infierno de fuego y toda otra horrible parafernalia de la enferma y aterrorizada imaginación del hombre. Dios existe y el Eterno, Todopoderoso, Omnipresente Dios es el amoroso Padre de la humanidad.</p><p>Si tú meditaras sobre este hecho, hasta que pudieras tener algún grado de entendimiento de lo que esto realmente significa, la mayoría de tus dificultades y dolencias físicas desaparecerían, por ellas estar arraigadas en el miedo. Si sólo pudieras realizar en algún grado que la omnipotente Sabiduría es tu viviente y amoroso Padre, la mayoría de tus miedos se irían. Si puedes realizarlo completamente, toda cosa negativa en tu vida se desvanecerá. Ahora tu ves el objetivo que Jesús tenía en mente cuando puso esta cláusula primero.</p><p><i>Y creó Dios al hombre a su imagen, a imagen de Dios lo creó…</i> (Génesis 1:27).</p>"
      },
      febrero3: {
        dia: "3 DE FEBRERO",
        titulo: "PADRE NUESTRO",
        cuerpo: "<p>Ahora la plegaria dice, no 'Mi Padre', sino 'Padre Nuestro', y esto indica fuera de la posibilidad de error, la verdad de la hermandad del hombre. Fuerza, muy al principio, nuestra atención sobre el hecho que todos los hombres son hijos de un Padre y que: <i>ya no hay judío ni griego; no hay esclavo ni libre; no hay varón ni mujer, porque todos vosotros sois uno en Cristo Jesús. </i>(Gálatas 3:28). Aquí Jesús corta la ilusión de que los miembros de alguna nación, raza, territorio, grupo, clase o color son, en la visión de Dios, superiores a algún otro grupo.</p><p>El punto final es el mandato implícito, de que nosotros estamos por orar no sólo por nosotros mismos, sino por toda la humanidad. Ninguno de nosotros vive para sí mismo, aunque lo intentemos. En un sentido mucho más literal de lo que es conciente la gente, somos células de un cuerpo.</p><p>“Padre Nuestro”, un explosivo espiritual, que destruirá por último toda clase de esclavitud humana.</p>"
      },
    };
  },
};
</script>
