<template>
  <v-container>
    <v-row class="d-flex align-start">
      <v-col lg="9" class="my-5 pr-5">
        <h1 class="indigo--text text--lighten-1 text-center">
          UN AÑO CON EMMET FOX
        </h1>
        <div class="d-flex justify-center mb-5">
          <v-img src="../../public/emmet_fox.png" max-width="40%"></v-img>
        </div>
        <p class="mb-5 text-center">{{ efHoy.dia }} | {{ efHoy.titulo }}</p>

        <p class="text-justify" v-html="efHoy.cuerpo"></p>
      </v-col>
      <v-col lg="3" class="pl-2"><PanelDerecho /></v-col>
    </v-row>
  </v-container>
</template>
<script>

import PanelDerecho from "../components/Inicio/PanelDerecho";
export default {
  name: "UnAnoConEmmetFox",
  components: {
    PanelDerecho,
  },
  computed: {
    efHoy() {
      const emmetFoxHoy = this.$route.params.id;
      return this[emmetFoxHoy];
    },
  },
  data() {
    return {
      enero19: {
        dia: "19 DE ENERO",
        titulo: "LA LLAVE DE ORO",
        cuerpo: "<p>El estudio y la investigación son buenos en su propio tiempo y lugar, pero ninguna cantidad de alguno de ellos te sacará fuera de alguna dificultad concreta. Nada sino el trabajo práctico en tu propia consciencia lo hará.</p><p>La oración científica es la Llave de Oro para la armonía y la felicidad. No necesitas escuchar a nadie para esto y no deberías hacerlo. Simplemente trata eso y compruébalo por ti mismo.</p><p>Quien quiera que seas, donde quiera que puedas estar, la Llave de Oro para la armonía está en tu mano ahora. Esto es porque es Dios el que trabaja y no tú. Así tus limitaciones particulares o debilidades no cuentan en el proceso. Sólo eres el canal a través del cual la acción toma lugar y lo que deberías hacer primero es apartarte del camino.</p><p>El método actual del trabajo es la simplicidad. En sí, todo lo que tienes que hacer es esto: deja de pensar en la dificultad y piensa en Dios en su lugar. Esta es la regla completa y si solo tu voluntad hace esto, el problema cualquiera que sea, desaparecerá dentro de poco. Este puede ser grande o pequeño, puede concernir a la salud, a las finanzas, a un juicio, a una discusión, a un accidente o cualquier otra cosa concebible, pero cualquiera que sea, solo deja de pensar en ello y piensa en Dios en su lugar. Esto es todo lo que tienes que hacer.</p><p>La cuestión no puede ser más simple ¿Puede serlo? Y nunca falla cuando se le pone a prueba.</p><p><i>…más para Dios todo es posible.</i> (Mateo 19:26)</p>"
      },
      enero20: {
        dia: "20 DE ENERO",
        titulo: "ABRIENDO LA PUERTA",
        cuerpo: "<p>Trabaja ensayando lo que sabes acerca de Dios. Dios es Sabiduría, Verdad, increíble Amor. Dios está presente en todas partes, tiene infinito poder, sabe todas las cosas. No importa lo que pienses o entiendas de estas cosas, examínalas repetidamente. La regla es pensar en Dios y si estás pensando en tu dificultad, no estás pensando en Dios. Estar continuamente atisbando por sobre tu hombro a fin de ver cómo están progresando tus cosas es fatal, porque eso es pensar en el problema. Y debes pensar en Dios y en nada más. Tu propósito es quitar el pensamiento de la dificultad, fuera de tu conciencia por unos pocos momentos como mínimo, sustituyéndolo por el pensamiento de Dios. Si puedes llegar a estar tan absorbido en esta consideración del mundo espiritual, que realmente olvidas por un instante todo sobre el problema concerniente al cual empezaste a orar, dentro de poco encontrarás que esto no representa ninguna dificultad.</p><p><i>…tú hiciste los cielos, y los cielos de los cielos con todo su ejército, la tierra y todo lo que está en ella, los mares y todo lo que hay en ellos, y tu vivificas todas estas cosas…</i> (Nehenías 9:6)</p><p><i>¿No ve Él mis caminos, y cuenta todos mis pasos?</i> (Job 31:4)</p>"
      },
      enero21: {
        dia: "21 DE ENERO",
        titulo: "COMPARTIENDO LA LLAVE",
        cuerpo: "<p>Para aplicarle la “Llave de Oro” a una persona conflictiva o a una situación difícil, piensa: “Ahora voy a aplicarle la “Llave de Oro” a Juan o a María o a este atemorizador peligro”. Luego eleva a Juan, a María o al peligro dentro de la presencia de Dios y piensa solo en Él.</p><p>De allí en adelante la persona es, en alguna medida, más sabia y más espiritual. Un juicio pendiente u otra dificultad probablemente se desvanecerán sin convertirse en una crisis y se hará justicia a todas las partes concernientes.</p><p><i>…yo soy Dios, tú eres Dios, y tus palabras son verdad, y Tú haz prometido este bien a tu siervo.</i> (II Samuel 7:28)</p>"
      },
      enero22: {
        dia: "22 DE ENERO",
        titulo: "COMPARTIENDO LA LLAVE",
        cuerpo: "<p>Nosotros hemos dicho que la Llave de Oro es simple y así es. Pero, por supuesto no es fácil aplicarla. Si estás muy asustado o muy preocupado, puede ser difícil al principio sacar tus pensamientos fuera de las cosas materiales. Pero por repetir constantemente algunas afirmaciones de la verdad absoluta que te agraden, tales como “no hay poder sino Dios. Yo soy un hijo de Dios, lleno y rodeado por la perfecta paz; Dios es amor; o Dios me está guiando ahora”, por ejemplo, la mejor y la más simple de todas, “solamente Dios está conmigo”, encontrarás pronto que tu mente está aclarándose. No te esfuerces, tranquilízate, pero insiste. Cada vez que encuentres tu atención desviándose, solo cambia y vuélvela directamente a Dios.</p><p>No trates de encontrar por adelantado que la solución a tu dificultad está probablemente por producirse. Esto es un estorbo y solo demorará la demostración. Deja la cuestión de todos modos y medios a Dios. Tú quieres salir de tu dificultad y eso es suficiente, haz tu mitad y Dios nunca fallará en hacer la Suya.</p><p><i>…y todo aquél que invocare el nombre de Yo Soy será salvo…</i> (Joel 2:32)</p>"
      },
      enero23: {
        dia: "23 DE ENERO",
        titulo: "LA LEY DE SUSTITUCIÓN",
        cuerpo: "<p>Hay unas pocas grandes leyes que gobiernan todo pensamiento, como hay unas pocas leyes fundamentales en química. Nosotros sabemos que el control del pensamiento es la llave del destino. Además de aprender el control del pensamiento, debemos saber y entender esas leyes. Una de las grandes leyes mentales es la ley de sustitución. Esto significa que la única forma de liberarse de cierto pensamiento es sustituir este por otro. No puedes alejar directamente un pensamiento. Puedes hacer esto solamente sustituyendo este por otro. Si te digo: “No pienses en la Estatua de la Libertad”, por supuesto inmediatamente tú piensas en eso. Si tú dices: “No voy a pensar en la Estatua de la Libertad”, eso es pensar sobre ella. Pero si te interesas en otra cosa, olvidas todo acerca de la Estatua de la Libertad, y esto es un caso de sustitución.</p><p>Cuando los pensamientos negativos vienen a ti, no los combatas, pero piensa en algo positivo. Preferentemente piensa en Dios, pero si esto es difícil en el momento, vuelve tu atención hacia algo totalmente diferente.</p><p><i>Pero yo os digo: No resistáis al que es malo…</i> (Job 12:13)</p><p><i>Con Dios está la sabiduría y el poder; suyo es el consejo y la inteligencia.</i> (Mateo 5:39)</p>"
      },
      enero24: {
        dia: "24 DE ENERO",
        titulo: "LA LEY DE RELAJACIÓN",
        cuerpo: "<p>Otra de las grandes leyes mentales es la Ley de Relajación. En todo trabajo mental, el esfuerzo se frustra a si mismo. Esto es justo lo opuesto de lo que encontramos en el plano físico, pero esto no nos sorprenderá porque nosotros sabemos, que en varios casos las leyes de la mente son el reverso de las leyes de la materia.</p><p>En el plano físico, la intensidad con que presionas un taladro será lo rápido que atravieses un tablón. La intensidad con la que martilles un clavo, será lo pronto que este entre en la pared. Pero cualquier intento de presión mental fallará, porque al comenzar la momentánea tensión, la mente para de trabajar creativamente. Cuando tratas de forzar las cosas mentalmente, cuando tratas de apurarlas mentalmente, simplemente detienes tu poder creativo. En todo trabajo mental sé relajado, suave y no te apresures, porque el esfuerzo se frustra a sí mismo.</p><p><i>…en quietud y en confianza será vuestra fortaleza…</i> (Isaías 30:15)</p>"
      },
      enero25: {
        dia: "25 DE ENERO",
        titulo: "LA LEY DE ACTIVIDAD SUBCONSCIENTE",
        cuerpo: "<p>Tan pronto como la mente subconsciente acepte una idea, inmediatamente empieza a ponerla en efecto. Usa todos sus recursos (y estos son mucho mayores de lo que comúnmente es supuesto) para este fin. Usa toda pizca de conocimiento, que alguna vez haya recogido, la mayoría del cual has olvidado totalmente, para efectuar su propósito. Moviliza los variados poderes mentales que posees, la mayoría de los cuales nunca usas conscientemente.</p><p>Recurre a la ilimitada energía de la mente racional. Alinea todas las leyes de la naturaleza, como ellas operan, de ambas formas dentro y fuera de ti, para obtener su objetivo.</p><p>Algunas veces tiene éxito en su propósito inmediatamente. Algunas veces toma un tiempo corto, algunas toma un tiempo largo, pero si la cosa no es totalmente imposible, el subconsciente lo realizará, una vez que acepte la idea.</p><p>Esta ley es verdadera para ambas, buenas y malas ideas. Esta ley, cuando es usada negativamente trae; enfermedad, problemas, fracaso. Cuando es usada positivamente trae; curación, liberación, éxito. Nosotros damos las órdenes, el subconsciente hace el trabajo.</p><p><i>…su comunión íntima es con los justos.</i> (Proverbios 3:32)</p>"
      },
      enero26: {
        dia: "26 DE ENERO",
        titulo: "LA LEY DE LA PRÁCTICA",
        cuerpo: "<p>La práctica hace la perfección. Este familiar proverbio personifica una de las grandes leyes de la naturaleza humana y siendo una ley, nunca es rota bajo ninguna circunstancia.</p><p>Es simple, no hay logro sin práctica y la mayor práctica, con tal que sea hecha inteligentemente, con la mayor voluntad, competencia y la mayor prontitud, será lograda. Esto es verdadero en toda rama del esfuerzo humano. La práctica es el precio de la competencia.</p><p>En metafísica, los efectos de esta ley son particularmente notables. El control del pensamiento, es enteramente una cuestión de práctica inteligente.  Y la verdadera religión, puede bien ser resumida como la Práctica de la Presencia de Dios.</p><p><i>…sed hacedores de la palabra, y no tan solamente oidores…</i> (Santiago 1:22)</p>"
      },
      enero27: {
        dia: "27 DE ENERO",
        titulo: "LA LEY DEL PERDÓN",
        cuerpo: "<p>Es una inquebrantable ley mental, que tu tienes que perdonar a otros, si quieres superar tus dificultades y hacer algún real progreso espiritual.</p><p>La vital importancia del perdón, puede no ser obvia a primera vista, pero puedes estar seguro que no es por casualidad que todo gran maestro espiritual desde Jesús Cristo hacia abajo han insistido tan fuertemente sobre eso. </p><p>Tu debes perdonar injurias, no solo en palabras o externamente sino también en tu corazón; y eso es lo extenso y lo breve de ello. Tú haces esto no por el bien de los demás sino por el tuyo. El resentimiento, la condenación, la ira, desear ver a alguien castigado, son cosas que pudren tu alma. Cosas como estas, atan tus problemas a ti con remaches. Te ponen grilletes a otros problemas, que en realidad no tienen en absoluto nada que ver con las quejas originales en sí.</p><p><i>No devolviendo mal por mal, ni maldición por maldición, sino por el contrario bendiciendo, sabiendo que fuisteis llamados para que heredaseis bendición.</i> (I Pedro 3:9)</p>"
      },
    };
  },
};
</script>
